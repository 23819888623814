
import { Component, Vue, } from 'vue-property-decorator'
import HeaderClient from '@/views/Implementador/Clients/HeaderClient.vue'
import { mapGetters, } from 'vuex'

@Component({
  components: {
    HeaderClient,
  },
  computed: {
    ...mapGetters('BusinessModule', ['getSelectedProduct',]),
    ...mapGetters('GapModule', ['getPublicGap', 'getCategorieSelected',]),
  },
})
export default class ClientsLayoutComponent extends Vue {
  private Business = this.$store.state.BusinessModule.BusinessState.businessSelected
}
