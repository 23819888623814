
import { Component, Vue, Watch, } from 'vue-property-decorator'
import { LinksInterface, } from '@/store/types/Polimorfic/Resources'

@Component
export default class SearchBar extends Vue {
  private searchText = ''
  private placeholder = ''
  private state = []
  private selectedGap = this.$store.state.GapModule.GapState.categories
  private key = ''
  private links_state: LinksInterface[] = []

  private viewData(route: string) {
    switch (route) {
      case 'category':
        this.placeholder = 'Buscar categoría'        
        this.state = this.$store.state.GapModule.GapState.categories
        this.key = 'name'
        break
      case 'knowledgeBase':
        this.placeholder = 'Buscar artículo'
        this.state = this.$store.state.PolimorficModule.PolimorficState.resources
        this.state.forEach((element: any) => {
          element.links.forEach((data: any) => {
            this.links_state.push(data)
          })
        })

        this.key = 'name'
        break
      default:
        this.placeholder = ''
        break
    }
  }

  private searchResults() {
    let filtered = this.state
    let knowledgeBaseFilter = this.links_state
    if (this.searchText) {
      if (this.$route.name == 'knowledgeBase') {
        knowledgeBaseFilter = this.links_state.filter(
          (item: any | null) =>
            item[this.key].toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
        )
        this.$store.commit('SearchModule/SET_SEARCH_RESULTS', knowledgeBaseFilter)
        return
      } else {
        filtered = this.state.filter(
          (item: any | null) =>
            item[this.key].toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
        )
      }
      this.$store.commit('SearchModule/SET_SEARCH_RESULTS', filtered)
    } else {
      this.$store.commit('SearchModule/SET_SEARCH_RESULTS', this.state)
    }
  }

  @Watch('$route', { immediate: true, deep: true, })
  onRouteChanged(newVal: any) {
    this.viewData(newVal.name)
    this.searchText = ''
    this.$store.commit('SearchModule/SET_SEARCH_TEXT', '')
    this.$store.commit('SearchModule/SET_SEARCH_RESULTS', [])
    this.searchResults()
  }
  @Watch('$store.state.GapModule.GapState.categories')
  onGapChanged(newVal: any) {
    if (this.$store.state.AuthModule.AuthState.role.includes('Implementador')) {
        this.placeholder = 'Buscar categoría'
        this.state = newVal
        this.key = 'name'
    }
  }
}
