
import { Component, Vue, Prop } from 'vue-property-decorator'
import { FrameworkInterface } from '@/store/types/Polimorfic/Framework'
import { ClickOutside } from 'vue-click-outside'
import { mapGetters } from 'vuex'
import SearchBar from '@/components/SearchBar/Index.vue'

@Component({
  components: {
    SearchBar,
  },
  directives: {
    ClickOutside,
  },
  computed: {
    ...mapGetters('PolimorficModule', ['getFrameworkSelected']),
    ...mapGetters('BusinessModule', { business: 'getSelectedProduct' }),
    ...mapGetters('GapModule', ['getAdvancedGap', 'getPublicGap']),
  },
})
export default class HeaderClient extends Vue {
  private userId = this.$route.params.id
  private menuFrame = false
  private business: any

  @Prop([String]) readonly client: string | undefined
  @Prop([Boolean]) readonly showPublic: boolean | undefined

  private publicGap() {
    this.$router.push(`/app/clients/${this.userId}/compliance`)
    this.$mixpanel.track('Avance Publicado', {
      Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
    })
  }

  private openModalContact() {
    this.$store.dispatch('BusinessModule/openModal')
  }

  private mixPanel(option: string) {
    let translateOption = ''
    switch (option) {
      case 'clientPlan':
        translateOption = 'Implementacion'
        this.$mixpanel.track('Sector Empresa', {
          'Opción Seleccionada': translateOption,
          Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
        })
        break
      case 'clientMaintenance':
        translateOption = 'Mantención'
        this.$mixpanel.track('Sector Empresa', {
          'Opción Seleccionada': translateOption,
          Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
        })
        break
      case 'clientDataroom':
        translateOption = 'Dataroom'
        this.$mixpanel.track('Sector Empresa', {
          'Opción Seleccionada': translateOption,
          Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
        })
        break
      case 'clientHistory':
        translateOption = 'Historial'
        this.$mixpanel.track('Sector Empresa', {
          'Opción Seleccionada': translateOption,
          Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
        })
        break
      case 'clientFileBusiness':
        translateOption = 'Ficha Técnica'
        this.$mixpanel.track('Sector Empresa', {
          'Opción Seleccionada': translateOption,
          Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
        })
        break
      case 'contacts':
        translateOption = 'Responsables'
        this.$mixpanel.track('Sector Empresa', {
          'Opción Seleccionada': translateOption,
          Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
        })
        break
    }
  }

  private goToRoute(name: string) {
    this.mixPanel(name)
    this.menuFrame = false
    if (name === 'category') {
      this.$store.commit('GapModule/SET_VERSION_CATEGORIES', 0)
    }

    this.$router.push({ name: name, params: { id: this.userId } })
  }

  goEditBusiness() {
    this.$router.push({ name: 'clientEditFileBusiness' })
  }

  private changeGapVersion(element: number) {
    this.$store.commit('GapModule/SET_VERSION_CATEGORIES', element)
  }

  private openMenu() {
    if (this.$route.name !== 'category') {
      this.$router.push({ name: 'category', params: { id: this.userId } })
      return
    }
    this.menuFrame = !this.menuFrame
  }

  private hide() {
    this.menuFrame = false
  }

  private selectFramework(element: FrameworkInterface) {
    this.$store.commit('PolimorficModule/SET_FRAMEWORK_SELECTED', element)
    this.$mixpanel.track('Sector Empresa', {
      Normativa: element.name,
      Empresa: this.$store.state.BusinessModule.BusinessState.businessSelected.name,
    })
  }
  private getNameCompany() {
    return this.$store.state.BusinessModule.BusinessState.businessSelected.name
      ? this.$store.state.BusinessModule.BusinessState.businessSelected.name
      : this.$store.getters['AuthModule/getCompanyNameSelected']
  }
}
